<template>
  <div ref="playerRoot" class="playerRoot fullscreen">
    <div
      class="playerContainer"
      style="overflow: hidden; background-color: black"
    >
      <!-- VIDEO PLAYER -->
      <div
        class="player text-center"
        :id="playerId"
        @click.stop="showHideOverlayControls"
      />
    </div>

    <transition name="fade">
      <div class="overlayControls" v-show="showOverlayControls">
        <!-- TOP - TITLE + CLOSE  -->
        <v-container fluid pa-0 px-4 pt-6 ma-0 height="100%">
          <v-row
            class="ma-0 pa-0"
            style="justify-content: space-between; align-items: end"
          >
            <v-col cols="10" class="ma-0 pa-0 blurEnds">
              <vue-marquee-slider
                class="scrollTitle"
                ref="slider"
                id="marquee-slider-loop"
                :speed="30000"
                :space="50"
                :repeat="0"
                :paused="false"
                :width="500"
              >
                <div v-for="index in 2" :key="index">
                  <div class="white--text smallHeadline smallHeadline1line">
                    {{ itemTitle }}
                  </div>
                </div>
              </vue-marquee-slider>
            </v-col>
            <v-col cols="1" class="ma-0 pa-0" align="right">
              <v-btn
                :ripple="false"
                text
                icon
                color="white"
                @click="onClose()"
                ma-0
                pa-0
                class="tiny-button"
                v-show="showCloseMinimizeButtons"
              >
                <v-icon class="small">$vuetify.icons.close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
        <v-container
          fluid
          fill-height
          pa-0
          px-4
          ma-0
          style="position: absolute; top: 0; z-index: -1"
        >
          <!-- MIDDLE PART - REPLAY, PLAY AND SKIP -->
          <v-row class="ma-0 pa-0" align="center" justify="space-around">
            <v-btn
              :ripple="false"
              v-show="!isErrored"
              text
              icon
              color="white"
              @click="$root.mediaPlayer.replay10()"
              class="ma-0 pa-0 large-button"
            >
              <v-icon size="40">$vuetify.icons.replay10</v-icon>
            </v-btn>
            <v-btn
              :ripple="false"
              v-show="!$root.mediaPlayer.isPlaying && !isErrored"
              text
              icon
              color="white"
              @click="$root.mediaPlayer.play()"
              class="ma-1 pa-0 x-large-button"
            >
              <v-icon size="50">$vuetify.icons.play </v-icon>
            </v-btn>
            <v-btn
              :ripple="false"
              v-show="$root.mediaPlayer.isPlaying && !isErrored"
              text
              icon
              color="white"
              @click="$root.mediaPlayer.pause()"
              class="ma-1 pa-0 x-large-button"
            >
              <v-icon size="50">$vuetify.icons.pause </v-icon>
            </v-btn>
            <v-btn
              :ripple="false"
              v-show="!isErrored"
              text
              icon
              color="white"
              @click="$root.mediaPlayer.forward10()"
              class="ma-0 pa-0 large-button"
            >
              <v-icon size="40">$vuetify.icons.forward10</v-icon>
            </v-btn>
          </v-row>
        </v-container>
        <v-container
          fluid
          pa-0
          px-4
          pb-6
          ma-0
          style="position: absolute; bottom:0"
        >
          <!-- BOTTOM PART - PROGRESS -->
          <v-row class="ma-0 pa-0">
            <v-layout align-center justify-center row pa-0 ma-0>
              <v-flex xs12 class="text-center">
                <v-slider
                  ref="slider"
                  height="4px"
                  hide-details
                  color="#627568"
                  class="progress my-2 pa-0"
                  background-color="#f0f6f0"
                  track-color="#f0f6f0"
                  track-fill-color="#627568"
                  v-bind="{
                    value:
                      $root.mediaPlayer == null
                        ? 0
                        : $root.mediaPlayer.currentPlayPercentage,
                  }"
                  v-on:input="
                    if (draggingSlider) {
                      $root.mediaPlayer.currentPlayPercentage = $event
                    }
                  "
                  v-on:change="$root.mediaPlayer.seekToPercentage($event)"
                  v-on:start="startDraggingSlider"
                  v-on:end="stopDraggingSlider"
                  step="0"
                  max="10000"
                />
              </v-flex>
              <v-flex xs12 mx-auto>
                <v-row>
                  <v-col class="text-left mediaDurationText secondary--text">
                    {{
                      (draggingSlider
                        ? $root.mediaPlayer.timeFromPercentage
                        : $root.mediaPlayer.currentPlaySeconds)
                        | timeInColonFormat
                    }}
                  </v-col>
                  <v-col class="text-right mediaDurationText secondary--text"
                    >-{{ $root.mediaPlayer.duration | timeInColonFormat }}
                  </v-col>
                </v-row>
              </v-flex>
            </v-layout>
          </v-row>

          <v-row class="ma-0 pa-0 pt-2 me-n3" xs12>
            <Share :dark="true" :item="item" mediaType="video" />
          </v-row>
        </v-container>
      </div>
    </transition>

    <!-- LOAD ERROR CONTAINER -->
    <v-container
      class="overlayControls text-center"
      style="pointer-events: none"
      fill-height
      fluid
      v-if="isErrored"
    >
      <v-row align="center" justify="center">
        <v-col>
          <div class="storyText" style="color: white">
            {{ $t("media_failed_to_load") }}
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import PlayControllerBase from "./PlayControllerBase"
  import Share from "./Share.vue"

  export default {
    extends: PlayControllerBase,
    components: {
      Share,
    },
    props: {
      playerId: {
        type: String,
        default: function() {
          return "videoPlaceholderController" + this.$route.name
        },
      },
      showCloseMinimizeButtons: {
        type: Boolean,
        default: function() {
          return true
        },
      },
    },
    watch: {
      "$store.state.showingFullScreenVideo"(value, ignoredOldValue) {
        if (!value && this.$store.state.isLandscapeMode) {
          // If landscape and exit full screen, that means "close".
          this.close()
        }
      },
    },
    mounted() {
      // Hardcode slider to LTR
      this.$refs.slider.$vuetify = {}
      this.storeWatchObject = this.$store.watch(
        (state) => state.isLandscapeMode,
        (newValue, ignoredOldValue) => {
          console.log("Detected orientation change in PlayControllerVideo")
          if (newValue == true) {
            // If landscape, set full screen always
            this.$store.commit("showingFullScreenVideo", true)
          }
        }
      )
      if (this.$store.state.isLandscapeMode) {
        this.$store.commit("showingFullScreenVideo", true)
      }
    },

    destroyed() {
      if (this.storeWatchObject != null) {
        this.storeWatchObject()
        this.storeWatchObject = null
      }
      this.$store.commit("showingFullScreenVideo", false)
    },
    computed: {
      alwaysFullscreen() {
        return this.$store.state.isLandscapeMode
      },
      showVideoFullscreen() {
        return (
          this.$root.mediaPlayerItem != null &&
          this.$root.mediaPlayerItem.hasVideoAttachment() &&
          this.$store.state.showingFullScreenVideo
        )
      },
    },
    methods: {
      update() {
        PlayControllerBase.methods.update.call(this)
      },
      onClose() {
        this.$store.commit("showingFullScreenVideo", false)
        this.$root.clearMediaItem()
      },
    },
  }
</script>

<style scoped>
  .ltr {
    direction: ltr;
  }

  .playerRoot {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    /* 16:9 */
    height: 0;
    /*var(--v-video-player-height);*/
    padding-bottom: 56.25%;
    /* 16:9 */
    height: 0;
    /*var(--v-video-player-height);*/
    z-index: 30;
    transition: 0.5s;
  }

  .playerContainer,
  .overlayControls {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .player {
    min-height: 0;
    min-width: 0;
    width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  .playerRoot.fullscreen {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    z-index: 966;
    padding-bottom: initial;
  }

  .overlayControls {
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 950;
  }

  .v-btn {
    min-width: 0;
    padding: 0;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }

  .fade-enter,
.fade-leave-to
/* .fade-leave-active below version 2.1.8 */
.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
 {
    opacity: 0;
  }

  .image {
    width: 65px;
    height: 65px;
    object-fit: cover;
  }
</style>

<style>
  .player video {
    height: 100%;
    width: 100%;
    overflow: hidden;
    object-fit: contain;
  }

  .v-slider__track-container {
    height: 4px !important;
  }

  .v-slider__track-fill,
  .v-input__slot,
  .v-slider__track-background {
    border-radius: 6px;
  }

  .v-slider--horizontal {
    margin: 0 !important;
  }

  .v-slider--horizontal {
    margin: 0 !important;
  }

  .v-slider__thumb {
    left: -7px;
    height: 14px;
    width: 14px;
  }
</style>
