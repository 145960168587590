<template>
    <v-container
        fluid
        ma-0
        pa-0
        :class="{
            dodgeDockedPlayer: this.$root.mediaPlayerDocked && this.$root.mediaPlayerItem != null,
        }"
    >
        <v-layout>
            <v-flex xs12>
                <v-app-bar flat fixed class="black--text toolbar pa-0" :style="cssProps">
                    <v-app-bar-nav-icon @click="onClose()" :style="cssProps">
                        <v-icon size="22" class="backButton ms-1 " color="black">
                            $vuetify.icons.back
                        </v-icon>
                    </v-app-bar-nav-icon>
                    <Share class="share" :style="cssProps" :item="item" mediaType="article" />
                    <!--<v-toolbar-title class="toolbarTitle">{{ item.title }}</v-toolbar-title>-->
                </v-app-bar>
                <v-card color="white" flat :style="cssProps" style="position: relative; top: 60px">
                    <v-container :class="{ noImage: !hasImage }" class="contentBlock">
                        <div class="topic featured mb-1">{{ item.getCategoryName() }}</div>
                        <div
                            style="grid-column: 1; grid-row: 1; align-self: end; z-index: 80"
                            class="titleBlock"
                        >
                            <div class="featuredHeadline featuredHeadline6lines mb-2">
                                {{ item.title }}
                            </div>
                            <div class="featuredDescription my-4">{{ item.description }}</div>
                            <div class="itemDateAndCredit" v-if="item.author && item.author != ''">
                                <div>
                                    <span class="author">
                                        {{
                                            $t("by_author", {
                                                author: item.author,
                                            })
                                        }}
                                    </span>
                                    <span class="verticalCenter" style="padding-bottom:3px">
                                        {{ String.fromCharCode(160) }}
                                    </span>
                                </div>
                                <DateView class="date verticalCenter" :date="item.pubDate" ago />
                            </div>
                        </div>

                        <!--<hr v-if="item.description != null && item.description.length > 0" class="teaserSeparator" />-->
                    </v-container>
                    <div class="imageBackdrop">
                        <div
                            @click.prevent="showZoomImage(imageSrc)"
                            class="imageContainer"
                            style="display: grid"
                        >
                            <v-img
                                :src="imageSrc"
                                style="grid-column: 1; grid-row: 1"
                                v-if="hasImage"
                                @error="$logger.logFetchError(imageSrc)"
                            >
                                <viewer
                                    ref="zoomViewer"
                                    v-if="zoomedImage != null"
                                    :options="zoomOptions"
                                    style="width: 100%; height: 100%"
                                >
                                    <img
                                        :src="zoomedImage"
                                        alt="podart"
                                        style="width: 100%; height: 100%;object-fit: cover;z-index: -1"
                                    />
                                </viewer>
                            </v-img>
                        </div>
                        <VideoPlayButton
                            v-if="playableVideo"
                            :item="item"
                            :large="true"
                            :playColor="'white'"
                            :showMediaPlayer="true"
                            v-on:playStarted="onPlayStarted($event)"
                        />
                        <!-- <div> {{ $t("image_credit") }} </div> -->
                      
                    </div>
                    <div class="mt-2 featureImageDescription">{{ item.imageDescription}}</div>
                    <div
                        class="px-4 py-4 ma-0 my-4 audioWrap"
                        v-if="playableAudio"
                        v-on:playStarted="onPlayStarted($event)"
                    >
                        <PlayButton
                            :large="false"
                            class="outlined"
                            v-if="playableAudio"
                            :item="item"
                            :playColor="'green'"
                            :showMediaPlayer="true"
                        />
                        <span class="ms-3">{{ $t("listen_now") }}</span>
                    </div>
                    <v-container pt-6 :class="{ noImage: !hasImage }" class="contentBlock">
                        <div
                            @click="handleClicks"
                            v-html="item.content"
                            class="storyText dynamic-content"
                            ref="itemBody"
                        />
                    </v-container>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import ItemBase from "./ItemBase";
    import Share from "../components/Share";
    import DateView from "../components/DateView";
    import "viewerjs/dist/viewer.css";
    import Viewer from "v-viewer/src/component.vue";
    import VideoPlayButton from "./VideoPlayButton.vue";
    import PlayButton from "../components/PlayButton.vue";

    export default {
        name: "FullScreenItem",
        extends: ItemBase,
        components: {
            Share,
            Viewer,
            DateView,
            VideoPlayButton,
            PlayButton,
        },
        data: () => ({
            moveFraction: 1,
            fadeFraction: 0,
            zoomedImage: null,
        }),

        destroyed: function() {
            this.$el.parentElement.removeEventListener("scroll", this.onScroll);
        },

        mounted: function() {
            this.$el.parentElement.addEventListener("scroll", this.onScroll);

            this.insertTwitterScript();

            // Set handler for when zoomed image is closed. Need to do it here, because
            // we want a reference to "this".
            this.zoomOptions.hidden = this.hideZoomImage;

            if (!this.hasImage) {
                this.moveFraction = 0;
                this.fadeFraction = 0;
            } else {
                this.moveFraction = 1;
                this.fadeFraction = 1;
            }
        },
        computed: {
            cssProps() {
                return {
                    "pointer-events": "all",
                    "--v-move-fraction": this.moveFraction,
                    "--v-fade-fraction": this.fadeFraction,
                };
            },
            iconColor() {
                return "rgba(calc(255 * var(--v-fade-fraction)),calc(255 * var(--v-fade-fraction)),calc(255 * var(--v-fade-fraction)),1)";
            },
            playerColor() {
                return "rgba(calc(255 * var(--v-fade-fraction)),calc(255 * var(--v-fade-fraction)),calc(255 * var(--v-fade-fraction)),calc(1 - var(--v-fade-fraction)))";
            },
            imageTitlePlayerColor() {
                return "rgba(255, 255, 255, var(--v-fade-fraction))";
            },
            playableAudio() {
                return this.item != null && this.item.hasAudioAttachment();
            },
            playableVideo() {
                return this.item != null && this.item.hasVideoAttachment();
            },
            zoomOptions() {
                const credit = this.imageCredit;
                return {
                    toolbar: false,
                    minZoomRatio: 0.5,
                    maxZoomRatio: 5,
                    tooltip: false,
                    navbar: false,
                    className: "zoomImageViewer",
                    title: function() {
                        return credit;
                    },
                };
            },
            imageCredit() {
                if (this.item.imageCredit != null && this.item.imageCredit != "") {
                    return this.$t("image_credit", {
                        author: this.item.imageCredit,
                    });
                } else {
                    return "";
                }
            },
            imageDescription() {
                if (this.item.imageDescription != null && this.item.imageDescription != "") {
                    return this.item.imageDescription
                } else {
                    return ""
                }
            }
        },
        methods: {
            onClose() {
                this.$emit("close");
            },
            onScroll(e) {
                if (this.hasImage) {
                    let offsetTop = e.target.scrollTop;
                    this.moveFraction = Math.min(1, Math.max(0, 1 - offsetTop / 150)).toFixed(2);
                    if (this.moveFraction < 0.2) {
                        this.fadeFraction = (this.moveFraction / 0.2).toFixed(2);
                    } else {
                        this.fadeFraction = 1;
                    }
                }
            },

            // From https://campushippo.com/lessons/how-to-convert-rgb-colors-to-hexadecimal-with-javascript-78219fdb
            //
            rgbToHex(rgb) {
                var hex = Number(rgb).toString(16);
                if (hex.length < 2) {
                    hex = "0" + hex;
                }
                return hex;
            },

            fullColorHex(r, g, b) {
                var red = this.rgbToHex(r);
                var green = this.rgbToHex(g);
                var blue = this.rgbToHex(b);
                return "#" + red + green + blue;
            },

            pause() {
                this.$root.mediaPlayer.pause();
            },

            play() {
                if (this.$root.mediaPlayerItem != this.item) {
                    this.item.autoplay = true;
                    this.$root.setMediaItem(this.item);
                } else {
                    this.$root.mediaPlayer.play();
                }
                this.$root.mediaPlayerDocked = true;
            },

            onPlayStarted(ignoredEvent) {
                // If this is a video, we play it in the list view, so open that
                if (
                    this.$root.mediaPlayerItem != null &&
                    this.$root.mediaPlayerItem.hasVideoAttachment()
                ) {
                    this.$emit("close");
                    this.$root.mediaPlayerDocked = false;
                } else {
                    this.$root.mediaPlayerDocked = false;
                }
            },

            showZoomImage(source) {
                // Check that we are still on this page, we might have reacted to a swipe event that we could not prevent.
                if (
                    this.$store.state.fullScreenItems != null &&
                    this.$store.state.fullScreenItems[this.$store.state.fullScreenItemIndex] ==
                        this.item
                ) {
                    this.zoomedImage = source;
                    this.$nextTick(() => {
                        this.$refs.zoomViewer.$viewer.show();
                    });
                }
            },

            hideZoomImage(ignoredEvent) {
                this.zoomedImage = null;
            },

            insertTwitterScript() {
                var script = document.getElementById("twitterScript");
                if (script == null) {
                    console.log("Twitter script link not found, creating!");
                    script = document.createElement("script");
                    script.id = "twitterScript";
                    script.async = true;
                    script.src = "https://platform.twitter.com/widgets.js";
                    script.onload = function(ignoredF) {
                        if (this.$refs.itemBody != null) {
                            window.twttr.widgets.load(this.$refs.itemBody.$el);
                        }
                    }.bind(this);
                    document.head.appendChild(script);
                } else if (window.twttr != null) {
                    console.log("Twitter script found, reusing");
                    if (this.$refs.itemBody != null) {
                        window.twttr.widgets.load(this.$refs.itemBody.$el);
                    }
                }
            },
            // Adapted from here: https://dennisreimann.de/articles/delegating-html-links-to-vue-router.html
            //
            // We handle all links, make sure that target is set to "_blank".
            handleClicks($event) {
                // ensure we use the link, in case the click has been received by a subelement
                let { target } = $event;
                let originalTarget = target;

                while (target && target.tagName !== "A") target = target.parentNode;
                // handle only links that occur inside the component and do not reference external resources
                if (target && target.matches(".dynamic-content a") && target.href) {
                    // some sanity checks taken from vue-router:
                    // https://github.com/vuejs/vue-router/blob/dev/src/components/link.js#L106
                    const { altKey, ctrlKey, metaKey, shiftKey, button, defaultPrevented } = $event;
                    // don't handle with control keys
                    if (metaKey || altKey || ctrlKey || shiftKey) return;
                    // don't handle when preventDefault called
                    if (defaultPrevented) return;
                    // don't handle right clicks
                    if (button !== undefined && button !== 0) return;
                    // don't handle if `target="_blank"`
                    if (target && target.getAttribute) {
                        const linkTarget = target.getAttribute("target");
                        if (/\b_blank\b/i.test(linkTarget)) return;
                    }
                    // don't handle same page links/anchors
                    const url = new URL(target.href);
                    const to = url.pathname;
                    if (window.location.pathname !== to) {
                        target.target = "_blank";
                    }
                } else if (originalTarget.tagName === "IMG") {
                    // This is not a link. It is, however, an image. Zoom!
                    this.showZoomImage(originalTarget.src);
                    return;
                }
            },
        },
    };
</script>

<style scoped>
    .author {
        font-weight: 600;
        font-size: 12px;
        line-height: 1;
    }

    .toolbar {
        pointer-events: none;
        height: var(--v-header-size);
        width: 100%;
        background-color: transparent !important;
        z-index: 100;
        padding: 0 auto;
        background-color: white !important;
    }

    .v-app-bar__nav-icon {
        margin-left: -16px !important;
    }

    .toolbarTitle {
        position: relative;
        color: rgba(
            calc(255 * var(--v-fade-fraction)),
            calc(255 * var(--v-fade-fraction)),
            calc(255 * var(--v-fade-fraction)),
            calc(1 - var(--v-fade-fraction))
        );
    }

    .v-application--is-rtl .backButton {
        -moz-transform: scale(-1, 1);
        -webkit-transform: scale(-1, 1);
        -o-transform: scale(-1, 1);
        -ms-transform: scale(-1, 1);
        transform: scale(-1, 1);
    }

    .smallHeadline {
        font-weight: 300 !important;
    }

    .contentBlock {
        /* Item paddings, t,r,b,l */
        padding: 8px var(--v-item-gutter-h) 16px var(--v-item-gutter-h);
    }

    .dodgeDockedPlayer {
        padding-bottom: 70px !important;
    }

    .imageBackdrop {
        /* background: var(--v-category-image);
  background-size: cover;
  background-repeat: no-repeat; */
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
        /* 16x9 */
        overflow: hidden;
        position: relative;
    }

    .imageContainer {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        /* padding: 60px var(--v-item-gutter-h) calc(var(--v-item-gutter-v) / 2) var(--v-item-gutter-h) !important;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1)); */
        overflow: hidden;
    }

    .share {
        height: 48px;
        width: 100%;
        position: sticky;
        top: 0px;
        right: 0;
        z-index: 90;
        margin-right: -16px !important;
    }

    .progress {
        position: sticky;
        top: 110px;
        z-index: 101;
    }

    .noImage {
        /* Prevent the content from disappearing under toolbar and share bar! */
        padding-top: 110px;
    }

    hr.teaserSeparator {
        border: 0;
        height: 0;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
        margin: 20px;
    }

    .audioWrap {
        background-color: #f5f5f5;
        color: black;
        font-size: calc(1.1 * var(--v-theme-font-size));
        display: flex;
        align-items: center;
    }

    .itemPlayButton >>> .itemPlayButtonIcon {
        filter: none;
        height: 16px;
        width: 16px;
    }

    .outlined {
        padding:5px;
        border: 1px solid var(--v-primary-base);
        border-radius: 50%;
    }
</style>

<style>
    .image-inline {
        width: 100% !important;
    }

    .zoomImageViewer {
        background-color: rgba(0, 0, 0, 0.8) !important;
    }

    .storyText div.image-inline,
    .storyText figure {
        /*width: 100% !important;*/
        margin-top: 35px;
        margin-bottom: 35px;
    }

    .storyText .image-caption,
    .storyText figcaption,
    .featureImageDescription {
        font-family: var(--v-theme-font);
        font-weight: 600;
        font-size: calc(0.8 * var(--v-theme-font-size-scaled));
        margin-left: var(--v-item-gutter-h);
        margin-right: var(--v-item-gutter-h);
        line-height: var(--v-theme-title-line-height);
    }

    .storyText .image-inline div {
        width: 100% !important;
    }

    .storyText .image-inline img {
        width: 100% !important;
        height: auto !important;
    }

    /* Links blue and not underlined */
    .storyText a {
        text-decoration: none;
        color: #0073cf;
        font-weight: 800;
    }

    .storyText strong {
        font-weight: bold;
    }

    /* Hide the zoom button from image captions */
    .storyText div#zoomattribute {
        display: none;
    }

    .storyText figure {
        width: calc(100% + 2 * var(--v-item-gutter-h)) !important;
        margin-left: calc(-1 * var(--v-item-gutter-h)) !important;
        margin-right: calc(-1 * var(--v-item-gutter-h)) !important;
        height: auto !important;
        overflow: none;
    }

    .storyText figure img {
        width: 100% !important;
        aspect-ratio: 16 / 9;
        object-fit: cover;
        height: auto !important;
    }

    .storyText div.image-inline {
        width: calc(100% + 2 * var(--v-item-gutter-h)) !important;
        margin-left: calc(-1 * var(--v-item-gutter-h)) !important;
        margin-right: calc(-1 * var(--v-item-gutter-h)) !important;
    }

    .storyText iframe:not([src^='https://www.facebook.com']):not([src^='https://www.youtube.com']) {
        /* width: calc(100% + 2 * var(--v-item-gutter-h)) !important;
  margin-left: calc(-1 * var(--v-item-gutter-h)) !important;
  margin-right: calc(-1 * var(--v-item-gutter-h)) !important; */
        overflow: hidden;
    }

    /* If no hard coded height, use auto */
    /* .storyText iframe:not([height]):not([src~='//www.facebook.com']):not([src~='//www.youtube.com']) {
  height: auto !important;
} */

    /* Facebook iframes */
    .storyText iframe[src^='https://www.facebook.com'] {
        width: calc(100% + 2 * var(--v-item-gutter-h)) !important;
        margin-left: calc(-1 * var(--v-item-gutter-h)) !important;
        margin-right: calc(-1 * var(--v-item-gutter-h)) !important;
        overflow: hidden;
        padding-top: 35px;
        padding-bottom: 35px;
    }

    /* Handle YouTube by setting aspect ratio */
    .storyText .videoWrapper {
        position: relative;
        padding-bottom: 56.25%;
        /* 16:9 */
        overflow: hidden;
        margin-top: 35px;
        margin-bottom: 35px;
        width: calc(100% + 2 * var(--v-item-gutter-h)) !important;
        height: auto !important;
        margin-left: calc(-1 * var(--v-item-gutter-h)) !important;
        margin-right: calc(-1 * var(--v-item-gutter-h)) !important;
    }

    .storyText .videoWrapper iframe {
        border: 0px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    /* End youtube specific */
</style>
