var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mainRoot",style:(_vm.cssProps)},[_c('v-app-bar',{staticClass:"mainListHeader",attrs:{"flat":""}},[_c('v-icon',{attrs:{"color":"accent","size":"45"}},[_vm._v("$vuetify.icons.logo")])],1),_c('div',{ref:"mainItemList",class:{
      mainItemList: _vm.useFadingAppBar,
      mainItemListMinusAppBar: !_vm.useFadingAppBar,
      'ma-0': true,
    },on:{"scroll":_vm.onHeaderScroll}},[(_vm.headerType != null)?_c('div',{staticClass:"mainListHeader pl-2 pr-2 pt-0 pb-0",staticStyle:{"position":"sticky","top":"0px","z-index":"3"}},[(_vm.headerType == 'saved')?_c('div',{staticClass:"tagContainer"},_vm._l((_vm.headerTagsSaved),function(tag){return _c('span',{key:tag.value,class:{
            'text-uppercase': true,
            topicTitle: true,
            topicTitleSelected:
              _vm.currentHeaderTag != null && _vm.currentHeaderTag == tag,
          },on:{"click":function($event){return _vm.onHeaderTag(tag)}}},[_vm._v(_vm._s(tag.name))])}),0):_vm._e(),(_vm.headerType == 'categories' && _vm.headerTagsCategories.length == 0)?_c('div',{staticClass:"tagContainer"},[_c('span',{class:{'text-uppercase': true, topicTitle: true}},[_vm._v(" ")])]):(_vm.headerType == 'categories')?_c('div',{staticClass:"tagContainer"},_vm._l((_vm.headerTagsCategories),function(tag){return _c('span',{key:tag.value,class:{
            'text-uppercase': true,
            topicTitle: true,
            topicTitleSelected:
              _vm.currentHeaderTag != null && _vm.currentHeaderTag.value == tag.value,
          },on:{"click":function($event){return _vm.onHeaderTag(tag)}}},[_vm._v(_vm._s(tag.name))])}),0):_vm._e()]):_vm._e(),(
        _vm.currentHeaderTag != null &&
          _vm.currentHeaderTag.value.startsWith('saved_') &&
          _vm.filteredItems.length == 0
      )?_c('v-container',{attrs:{"fluid":""}},[_c('v-layout',{attrs:{"fill-height":"","justify-center":"","align-center":""}},[_c('v-flex',{attrs:{"pa-3":""}},[_c('div',{staticClass:"text-center"},[_c('v-icon',{staticClass:"ma-5 pa-0",attrs:{"color":"secondary","x-large":""}},[_vm._v("$vuetify.icons.favorites")])],1),_c('div',{staticClass:"featuredDescription text-center"},[_vm._v(" "+_vm._s(this.$t("saved_placeholder"))+" ")])])],1)],1):(
        _vm.headerType == 'categories' && _vm.headerTagsCategories.length == 0
      )?_c('v-container',{attrs:{"fluid":""}},[_c('v-layout',{attrs:{"fill-height":"","justify-center":"","align-center":""}},[_c('v-flex',[_c('div',{staticClass:"text-center"},[_c('v-icon',{staticClass:"ma-5 pa-0",attrs:{"size":"40","color":"secondary"}},[_vm._v("$vuetify.icons.favorites ")])],1),_c('div',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t("categories_placeholder")))])])],1)],1):(_vm.currentHeaderTag != null)?_c('ItemListPlain',{staticClass:"pt-0 ma-0",attrs:{"items":_vm.filteredItems,"showFavorites":_vm.currentHeaderTag != null &&
          _vm.currentHeaderTag.value.startsWith('saved_')},on:{"itemClicked":function($event){return _vm.itemClicked($event)},"playItem":function($event){return _vm.playItem($event)},"playStarted":function($event){return _vm.onPlayStarted($event)}}}):_c('ItemList',{staticClass:"pt-0 ma-0",attrs:{"items":_vm.filteredItems,"plain":_vm.currentHeaderTag != null},on:{"itemClicked":function($event){return _vm.itemClicked($event)},"playItem":function($event){return _vm.playItem($event)},"playStarted":function($event){return _vm.onPlayStarted($event)}}})],1),( _vm.$root.mediaPlayerItem != null &&
        _vm.$root.mediaPlayer.isVideo )?_c('div',{staticClass:"videoItemList"},[_c('PlayControllerVideo',{staticStyle:{"flex-grow":"0","flex-shrink":"0"},attrs:{"playerId":_vm.videoPlayerId,"item":_vm.$root.mediaPlayerItem},on:{"close":function($event){return _vm.onClose()},"openFullscreen":function($event){return _vm.itemClicked($event)}}})],1):_vm._e(),(_vm.$store.state.fullScreenItems != null)?_c('div',{directives:[{name:"touch",rawName:"v-touch:swipe.right",value:(_vm.swipeRight),expression:"swipeRight",arg:"swipe",modifiers:{"right":true}},{name:"touch",rawName:"v-touch:swipe.left",value:(_vm.swipeLeft),expression:"swipeLeft",arg:"swipe",modifiers:{"left":true}}],staticClass:"fullScreenItem ma-0 pa-0"},[_c('v-window',{staticClass:"fullScreenItemInner",model:{value:(_vm.$store.state.fullScreenItemIndex),callback:function ($$v) {_vm.$set(_vm.$store.state, "fullScreenItemIndex", $$v)},expression:"$store.state.fullScreenItemIndex"}},_vm._l((_vm.$store.state.fullScreenItems),function(item,index){return _c('v-window-item',{key:'fs_' + item.guid,staticClass:"fullScreenItemInnerContent",attrs:{"value":index}},[_c('FullScreenItem',{attrs:{"item":item},on:{"close":function($event){return _vm.onCloseFullscreen()}}})],1)}),1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }