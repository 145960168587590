<template>
  <div :style="cssProps">
    <div class="imageBackdrop" @click="itemClicked()"></div>
    <div>
      <v-layout
        ref="card"
        class="titleContainer"
        justify-space-between
        align-end
        ma-0
        pa-0
        fill-height
      >
        <v-flex ma-0 pa-0>
          <div class="topic featured">{{ item.getCategoryName() }}</div>
          <div>
            <div class="featuredHeadline" @click="itemClicked()">
              {{ item.title }}
            </div>
            <div class="featuredDescription my-2">{{ item.description }}</div>
            <div class="d-flex align-center">
              <PlayButton
                v-if="playableVideo"
                :item="item"
                playerColor="black"
                :large="false"
                showMediaPlayer
                class="playButton"
                v-on:playStarted="onPlayStarted($event)"
              />
              <DateView
                class="date verticalCenter me-1"
                :date="item.pubDate"
                ago
              />
              <ItemType :item="item" color="secondary" />
            </div>
          </div>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>

<script>
  import ItemBase from "./ItemBase"
  import ItemType from "./ItemType"
  import DateView from "./DateView"
  import PlayButton from "./PlayButton"

  export default {
    extends: ItemBase,
    components: {
      DateView,
      PlayButton,
      ItemType,
    },
    computed: {
      cssProps() {
        return {
          "--v-category-image": "url(" + this.imageSrc + ")",
        }
      },
      playableAudio() {
        return this.item != null && this.item.hasAudioAttachment()
      },
      playableVideo() {
        return this.item != null && this.item.hasVideoAttachment()
      },
    },
  }
</script>

<style scoped>
  .featuredHeadline,
  .storyText,
  .tiny {
    color: #000 !important;
  }

  .topic.featured {
    color: var(--v-accent-base);
  }

  .featuredDescription {
    margin: 0.25rem 0;
  }

  .playButton {
    display: inline-block;
    margin-inline-start: -12px !important;
    /*margin-left: -12px !important;*/
    background-color: transparent !important;
  }

  .contentBlock {
    display: block;
  }

  .imageBackdrop {
    background: var(--v-category-image);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    /* 16x9 */
    overflow: hidden;
    position: relative;
  }

  .titleContainer {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* min-height: var(--v-theme-caption-image-height);
  max-height: 70vh; */
    padding: 12px var(--v-item-gutter-h) 0 var(--v-item-gutter-h) !important;
    overflow: hidden;
  }
</style>
