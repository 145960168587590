<template>
  <div class="buttonWrap" v-if="item != null && item.hasVideoAttachment()">
    <v-btn
      class="VideoPlay"
      :ripple="false"
      v-show="
        this.$root.mediaPlayer == null ||
          this.$root.mediaPlayer.item != item ||
          !this.$root.mediaPlayer.isPlaying
      "
      :color="playColor"
      tile
      icon
      large
      @click.stop="play()"
    >
      <v-icon class="itemPlayButtonIcon" large>$vuetify.icons.play </v-icon>
    </v-btn>
  </div>
</template>

<script>
  import ItemModel from "../models/itemmodel"

  export default {
    name: "PlayButton",
    props: {
      item: {
        type: ItemModel,
        default: function() {
          return new ItemModel()
        },
      },
      /**
       * Whether the media player should be shown when playback is started. Defaults to 'false'.
       */
      showMediaPlayer: {
        type: Boolean,
        default: function() {
          return false
        },
      },
      playColor: {
        type: String,
        default: function() {
          return "#fff"
        },
      },
    },

    mounted: function() {
      // If we are playing this item, hide the docked media player since we already
      // show the play/pause button next to the title.
      //if (this.$root.mediaPlayerItem == this.item) {
      //  this.$root.mediaPlayerDocked = this.showMediaPlayer;
      //}
    },
    methods: {
      pause() {
        this.$root.mediaPlayer.pause()
      },

      play() {
        if (this.$root.mediaPlayerItem != this.item) {
          // eslint-disable-next-line
          this.item.autoplay = true
          this.$root.setMediaItem(this.item)
        } else {
          this.$root.mediaPlayer.play()
        }
        this.$root.mediaPlayerDocked =
          this.item.hasAudioAttachment() && this.showMediaPlayer
        this.$emit("playStarted", this.item)
      },
    },
  }
</script>

<style>
  .buttonWrap {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
  }
</style>
