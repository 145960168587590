var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.item != null && (_vm.item.hasAudioAttachment() || _vm.item.hasVideoAttachment())
)?_c('div',{class:{
  'ma-0': true,
  'pa-1': true,
  itemPlayButton: !_vm.large,
  itemPlayButtonLarge: _vm.large,
}},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(this.$root.mediaPlayer != null &&
    this.$root.mediaPlayer.item == _vm.item &&
    this.$root.mediaPlayer.isPlaying
    ),expression:"this.$root.mediaPlayer != null &&\n    this.$root.mediaPlayer.item == item &&\n    this.$root.mediaPlayer.isPlaying\n    "}],class:{ 'ma-0': true, 'pa-0': true , 'tiny-button':!_vm.large},attrs:{"ripple":false,"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.pause()}}},[_c('v-icon',{staticClass:"itemPlayButtonIcon"},[_vm._v("$vuetify.icons.pause")])],1),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(this.$root.mediaPlayer == null ||
    this.$root.mediaPlayer.item != _vm.item ||
    !this.$root.mediaPlayer.isPlaying
    ),expression:"this.$root.mediaPlayer == null ||\n    this.$root.mediaPlayer.item != item ||\n    !this.$root.mediaPlayer.isPlaying\n    "}],class:{ 'ma-0': true, 'pa-0': true, 'tiny-button':!_vm.large },attrs:{"ripple":false,"icon":"","color":_vm.playerColor},on:{"click":function($event){$event.stopPropagation();return _vm.play()}}},[_c('v-icon',{staticClass:"itemPlayButtonIcon"},[_vm._v("$vuetify.icons.play")])],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }