<template>
  <v-container fluid grid-list-sm>
    <v-layout ref="card" xs12 style="background-color: var(--v-cardBackground-base);--v-image-actual-height: 70px"
      justify-space-between ma-0 pa-0>
      <v-flex @click="itemClicked()" flex-grow-1 v-bind:class="textClassObject" mt-0 pt-0 mb-0 pb-0
        style="display: flex; flex-direction: column;max-height: var(--v-image-actual-height);overflow:auto">
        <div class="pa-0" style="flex:1 1 auto;display: flex; flex-direction: column;overflow:auto">
          <div style="flex: 0 0 auto" ref="topic" class="topic" v-if="item.getCategoryName().length > 0">
            {{ item.getCategoryName() }}
          </div>
          <div style="flex: 1 1 auto;display:flex">
            <vue-resize-sensor style="flex: 1 1 auto" ref="itemTitleResizer" @resize="onTitleResize">
              <div style="position:absolute;top:0;left:0;right:0;bottom:0" ref="itemTitle"
                class="smallHeadline smallHeadline4lines">
                {{ item.title }}
              </div>
            </vue-resize-sensor>
          </div>
          <div style="flex: 0 0 auto" />
          <div style="flex: 0 0 auto;line-height:var(--v-theme-line-height)" ref="date"
            class="d-flex-inline align-stretch nobreak pa-0 ma-0">
            <DateView class="date me-1" :date="item.pubDate" ago />
            <ItemType :item="item" />
          </div>
        </div>
      </v-flex>
      <v-flex style="width: 100px;max-width: 30%" mt-0 pa-0 mb-0 v-if="hasImage || playable"
        v-bind:class="imageClassObject">
        <v-img ref="itemImage" :aspect-ratio="1 / 1" :key="item.guid + 'image'" class="image ma-0 mb-2 pa-0 text-center"
          :src="imageSrc" @error="$logger.logFetchError(imageSrc)" style="width: 100%" v-on="{ click: itemClicked }">
          <v-container ma-0 pa-0 fluid fill-height align-end>
            <v-row ma-0 pa-0 no-gutters>
              <v-spacer></v-spacer>
              <v-col cols="auto" ma-0 pa-0>
                <v-spacer></v-spacer>
                <vue-resize-sensor @resize="onImageResize" style="position:absolute;top:0;bottom:0;left:0;right:0" />
                <PlayButton :large="false" class="pa-0 ma-0 d-flex align-center justify-center" v-if="item.hasVideoAttachment()" :item="item"
                  :playerColor="hasImage ? 'white' : 'black'" showMediaPlayer v-on:playStarted="onPlayStarted($event)" />
              </v-col>
            </v-row>
          </v-container>
        </v-img>
      </v-flex>
      <v-flex
        style="flex: 0 1 auto"
        class="pa-0"
        order-xs1
        v-if="showFavorites"
      >
        <ItemFavoriteButton
          class="align-start justify-left"
          :item="item"
          mediaType="article"
        />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import ItemBase from "./ItemBase"
import ItemType from "./ItemType"
import ItemFavoriteButton from "./ItemFavoriteButton"
import ItemModel from "../models/itemmodel"
import DateView from "./DateView"
import PlayButton from "./PlayButton"

export default {
  extends: ItemBase,
  components: {
    DateView,
    PlayButton,
    ItemType,
    ItemFavoriteButton,
  },
  props: {
    item: {
      type: ItemModel,
      default: function () {
        return new ItemModel()
      },
    },
    showFavorites: {
      type: Boolean,
      default: function () {
        return false
      },
    }
  },
  // mounted() {
  //   console.log("Item mounted: " + this.item.title);
  // },
  methods: {
    onImageResize(ignoredElement) {
      const image = this.$refs.itemImage
      if (image) {
        this.$refs.card.style.setProperty(
          "--v-image-actual-height",
          image.$el.clientHeight + 4 + "px"
        )
      }
    },
    onTitleResize(ignoredSize) {
      // We figure out how much space we have, then set max-height to an even number of lines!
      //
      // 1. Get height of container
      const itemTitleResizer = this.$refs.itemTitleResizer
      const itemTitle = this.$refs.itemTitle
      const h = itemTitleResizer.$el.clientHeight

      // 2. Get line height and calculate an integer number of lines we can display
      var lineHeight = parseFloat(
        getComputedStyle(itemTitle).getPropertyValue("line-height")
      )
      var numLines = Math.floor(h / lineHeight)

      // 3. Set max-height to this value
      itemTitle.style.setProperty("max-height", numLines * lineHeight + "px")
    },
  },
  computed: {
    imageClassObject: function() {
      var sort = 3
      if (this.odd) {
        sort = 1
      }
      let sortClass = "order-xs" + sort
      let o = {}
      o[sortClass] = true
      return o
    },
    textClassObject: function() {
      var sort = this.hasImage || this.playable ? 2 : 1
      let o = {}
      if (this.odd != this.$vuetify.rtl) {
        sort = 3
        o["ms-2"] = true
        o["mr-0"] = true
      } else {
        o["mr-0"] = true
        o["mr-2"] = true
      }
      let sortClass = "order-xs" + sort
      o[sortClass] = true
      return o
    },
  },
}
</script>

<style scoped>
.contentBlock {
  display: block;
}

.imageContainer {
  position: relative;
  width: 100%;
  padding-top: 76%;
}

.imageContainerContent {
  display: grid;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.image {
  object-fit: cover;
  background-color: #efefef;
  border-radius: 2px;
}

.nobreak {
  overflow: hidden;
  white-space: nowrap;
  padding: 0;
}
</style>
