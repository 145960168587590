<template>
  <div :class="{
    'ma-0': true,
    'pa-1': true,
    itemPlayButton: !large,
    itemPlayButtonLarge: large,
  }" v-if="item != null && (item.hasAudioAttachment() || item.hasVideoAttachment())
  ">
    <v-btn :ripple="false" v-show="this.$root.mediaPlayer != null &&
      this.$root.mediaPlayer.item == item &&
      this.$root.mediaPlayer.isPlaying
      " icon @click.stop="pause()" :class="{ 'ma-0': true, 'pa-0': true , 'tiny-button':!large}">
      <v-icon class="itemPlayButtonIcon">$vuetify.icons.pause</v-icon>
    </v-btn>
    <v-btn :ripple="false" v-show="this.$root.mediaPlayer == null ||
      this.$root.mediaPlayer.item != item ||
      !this.$root.mediaPlayer.isPlaying
      " icon :color="playerColor" @click.stop="play()" :class="{ 'ma-0': true, 'pa-0': true, 'tiny-button':!large }">
      <v-icon class="itemPlayButtonIcon">$vuetify.icons.play</v-icon>
    </v-btn>
  </div>
</template>

<script>
import ItemModel from "../models/itemmodel"

export default {
  name: "PlayButton",
  props: {
    item: {
      type: ItemModel,
      default: function () {
        return new ItemModel()
      },
    },
    large: {
      type: Boolean,
      default: function () {
        return false
      },
    },
    playerColor: {
      type: String,
      default: function () {
        return "#000000"
      },
    },
    /**
     * Whether the media player should be shown when playback is started. Defaults to 'false'.
     */
    showMediaPlayer: {
      type: Boolean,
      default: function () {
        return false
      },
    },
  },

  mounted: function () {
    // If we are playing this item, hide the docked media player since we already
    // show the play/pause button next to the title.
    //if (this.$root.mediaPlayerItem == this.item) {
    //  this.$root.mediaPlayerDocked = this.showMediaPlayer;
    //}
  },
  methods: {
    pause() {
      this.$root.mediaPlayer.pause()
    },

    play() {
      if (this.$root.mediaPlayerItem != this.item) {
        // eslint-disable-next-line
        this.item.autoplay = true
        this.$root.setMediaItem(this.item)
      } else {
        this.$root.mediaPlayer.play()
      }
      this.$root.mediaPlayerDocked = this.item.hasAudioAttachment() && this.showMediaPlayer
      this.$emit("playStarted", this.item)
    },
  },
}
</script>
